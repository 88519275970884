.landing {
  padding-top: 0;
}

.intro-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bolder;
  font-family: "Verdana", "Courier New", Arial, sans-serif;
  @media screen and (max-width: 350px) {
    font-size: 23px;
  }
  @media screen and (min-width: 350px) and (max-width: 400px) {
    font-size: 26px;
  }
  @media screen and (min-width: 400px) and (max-width: 700px) {
    font-size: 30px;
    text-align: left;
  }
  @media screen and (min-width: 700px) and (max-width: 1000px) {
    font-size: 30px;
  }
  @media screen and (min-width: 1000px) {
    font-size: 2.5em;
  }

  span {
    color: #1589b8;
  }
}