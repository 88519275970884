//container for about page
.about {
  .background {
    display: flex;
    justify-content: center;
    text-align: center;
    @media screen and (max-width: 1050px) {
      flex-wrap: wrap;
    }
    padding: 40px;
  }

  h2, h3 {
    color: #1589b8;
    font-size: 30px;
    font-weight: bolder;
    padding-bottom: 2%;
    text-align: center;
  }

  picture {
    max-width: 400px;
    min-width: 100px;
    width: 100%;
    margin-right: 5%;
    @media screen and (max-width: 1050px) {
      margin-right: 0;
    }
  }

  img {
    width: 100%;
    border-radius: 20px;
    @media screen and (max-width: 700px) {
      display: none;
    }
  }

  div {
    align-self: center;
    padding: 0 10% 0 10%;
    line-height: 1.5;
    font-size: 20px;
  }

  a {
    color: #58CCED;
    font-weight: bold;
  }

  .icon-list {
    display: flex;
    justify-content: space-evenly;
    align-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .icon {
    width: 100%;
    max-width: 80px;
    min-width: 35px;
    display: inline;
    padding: 3% 0 3% 0;
    transition: transform 0.5s;
    &:hover {
      transform: scale(1.5);
    }
  }

  //Small Display Photo
  #small-profile-photo {
    display: inline;
    @media screen and (min-width: 700px) {
      display: none;
    }
    max-width: 200px;
    min-width: 50px;
    border-radius: 0;
  }
}