@use "reset";
@use "header";
@use "landing";
@use "about";
@use "portfolio";
@use "contact";
@use "page404";

//global attributes
section {
  padding-top: 15vh;
  font-family: "Verdana", "Courier New", Arial, sans-serif;
  color: white;
}

html {
  background-color: #202020;
}