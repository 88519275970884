header {
  background-color: #1589b8;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  //Logo Styling
  a {
    font-family: "Verdana", "Courier New", Arial, sans-serif;
    position: relative;
    padding: 10px;
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 1.2em;
    letter-spacing: 2px;

    &:hover {
      color: white;
    }
  }

  img {
    width: 6vw;
    min-width: 40px;
    max-width: 50px;
  }
}

.menu-icon {
  cursor: context-menu;
  @media screen and (min-width: 750px) {
    display: none;
  }
  //iPad Pro
  @media screen and (width: 1024px) {
    display: inline;
  }
}

.nav-links {
  display: flex;
  padding-right: 20px;
  @media screen and (max-width: 750px), (width: 1024px) {
    display: none;
  }

  &:hover {
    color: white;
  }
  .active {
    color: white;
  }
}

.mobile-nav-links {
  position: absolute;
  text-align: center;
  line-height: 1.5;
  top: 100%;
  background-color: #1589b8;
  width: 100%;
  right: 0;
  a {
    color: black;
  }

  &:hover {
    color: white;
  }
  .active {
    color: white;
  }

  @media screen and (min-width: 750px) {
    display: none;
  }
  //iPad Pro
  @media screen and (width: 1024px) {
    display: inline;
  }
}