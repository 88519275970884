.contact {
  display: flex;
  justify-content: center;
  text-align: center;

  article {
    width: 100%;
    max-width: 350px;
    min-width: 150px;
  }

  .profile-photo {
    background-color: #1589b8;
    padding: 2%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    img {
      width: 100%;
      max-width: 75px;
      min-width: 50px;
    }
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    background-color: #1589b8;
    padding: 2%;
    line-height: 1.5;
    margin-bottom: 2%;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    a {
      color: white;
    }
  }

  .contact-links {
    display: flex;
    justify-content: space-evenly;
  }

  img {
    width: 100%;
    max-width: 50px;
  }
}