.page404 {
  text-align: center;
  padding-top: 20%;

  h1 {
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 2%;
    color: #1589b8;
  }

  p {
    font-size: 30px;
  }
}