.portfolio {
  .card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: space-around;
    align-items: center;

    div {
      padding: 3%;
    }

    .title {
      padding-bottom: 2%;
      border-bottom: #202020 2px solid;
    }

    .description {
      margin-top: 3%;
    }
  }

  h2 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 2% 2% 2% 0;
    padding-bottom: 0.5%;
    border-bottom: white 2px solid;
    width: 200px;

    span {
      color: #1589b8;
      margin-left: 10px;
      font-size: 25px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  article {
    background-color: #1589b8;
    max-width: 350px;
    min-width: 75px;
    margin: 2% 1% 2% 1%;
    border: grey 2px solid;
    border-radius: 10px;
    overflow: hidden;
    line-height: 1.4;
  }

  img {
    width: 100%;
    height: 225px;
    //Zoom the Images to fit the defined space
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: 5px solid black;
  }
}
